.Navbar {
  position: fixed;
  background-color: #FFFFFF;
  top: 0;
  left: 0;
  width: 80vw;
  padding-top: 10px;
  height: 100px;
  padding-left: 10vw;
  padding-right: 10vw;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 4fr 1fr 1fr;
  z-index: 1;
}

.Navbar .dropdown {
  background-color: #F0F1F6;
  display: none;
  width: 40px;
}

.Navbar .dropdownReveal {
  display: none;
  position: fixed;
  height: 150px;
  width: 100px;
  top: 100px;
  border-radius: 20px;
  align-items: flex-start;
  background-color: inherit;
}

.Navbar .dropdownReveal button {
  font-size: 14px;
  text-align: center;
  margin-bottom: 10px;
}

.Navbar .miniGreenButton {
  padding-left: 24px;
  padding-right: 24px;
  padding-top: 10px;
  padding-bottom: 10px;
  margin-top: 0px;
}

.image {
  width: 40px;
}

.Navbar .noButton {
  border: none;
  text-align: left;
  margin-left: 20px;
  background-color: inherit;
  font-weight: 700;
  color: #9E9AA7;

}

.Navbar .greenButton, .Navbar .miniGreenButton{
  background-color: #2ACFCF;
  color: #FFFFFF;
  border-radius: 50px;
  border: none;
}

.Navbar .greenButton {
  margin: 20px 40px 20px 40px;
  width: 150px;
}


.Navbar .greenButton:hover {
  background-color: #1ABFBF;
  cursor: pointer;
}

.Navbar .miniGreenButton {

}


.Navbar .miniGreenButton:hover {
  background-color: #1ABFBF;
  cursor: pointer;
}

@media screen and (max-width: 800px) {
  .Navbar .dropdownReveal {
    display: flex;
    flex-direction: column;
    position: absolute;
    right: 0;
    padding: 20px;
  }
  .Navbar {
   grid-template-columns: 10fr 1fr;
  }
  .Navbar .dropdown {
    display: block;
  }
  .Navbar .desktop {
    display: none;
  }
}
