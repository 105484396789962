.PageInfo {
  background-color: #F0F1F6;
  padding-top: 40px;
  padding-bottom: 30px;
}

.PageInfo p {
  font-weight: 400;
  color: #9E9AA7;
}

.PageInfo h2 {
  margin-top: 15%;
}

.PageInfo .descriptionFlex {
  text-align: left;
}

.PageInfo .Statistics {
  max-width: 80vw;
  margin-left: auto;
  margin-right: auto;
}

.PageInfo .descriptionFlex {
  display: flex;
  justify-content: center;
  position: relative;
}

.PageInfo .image {
  position: absolute;
  top: -30px;
  width: 84px;
  left: 32px;
}

.PageInfo .greenBox {
  position: absolute;
  z-index: 0;
  top: 50%;
  height: 8px;
  width: 77%;
  background-color: #2ACFCF;
}

.PageInfo .square {
  position: relative;
  padding: 20px;
  margin: 2vw;
  border-radius: 20px;
  background-color: #FFFFFF;
  min-width: 300px;
  max-width: 400px;
  height: 269px;
}


.PageInfo .top {
  margin-top: 0px;
}

.PageInfo .middle {
  margin-top: 40px;
}

.PageInfo .bottom {
  margin-top: 80px;
}

@media screen and (max-width: 1200px) {
  .PageInfo .greenBox {
    position: absolute;
    z-index: 0;
    margin-left: auto;
    margin-right: auto;
    top: 0;
    height: 95%;
    width: 8px;
    background-color: #2ACFCF;

  }

  .PageInfo .middle {
    margin-top: 60px;
  }

  .PageInfo .bottom {
    margin-top: 60px;
  }

  .PageInfo .descriptionFlex {
    flex-direction: column;
    align-items: center;
    margin: 0;
    margin-top: 80px;
    position: relative;
  }
  .PageInfo .image {
    position: absolute;
    top: -30px;
    left: 40%;
  }
  .PageInfo h2 {
    margin-top: 60px;
  }
}
