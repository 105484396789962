.Header {
  margin-top: 160px;
  display: flex;
  align-items: center;
  justify-content: center;

}

.Header .Image {
  position: relative;
  right: 0;
  width: 45vw;
}

.Header .description {
  font-size: calc(11px + 0.5vw);
  font-weight: 400;
  margin-top: -30px;
  color: #9E9AA7;
}

.Header .text {
  margin: 5vw;
  margin-left: 0;
  max-width: 500px;
}

.Header .text h1 {
  font-size: calc(44px + 1.1vw);
  font-weight: 700;
}

.Header .desktop {
  display: flex;
  align-items: center;
  text-align: left;
  justify-content: space-between;
  width: 90vw;
  margin-left: 10vw;
}

.Header .greenButton {
  background-color: #2ACFCF;
  color: #FFFFFF;
  border-radius: 50px;
  padding: 20px 40px 20px 40px;
  border: none;
}

.Header .greenButton:hover {
  background-color: #1ABFBF;
  cursor: pointer;
}

.Header .mobile {
  width: 100vw;
  display: none;
}

@media screen and (max-width: 900px) {
  .Header .desktop {
    display: none;
  }

  .Header .text h1 {
    font-size: 40px;
  }

  .Header .text {
    align-self: center;
    justify-content: center;
    text-align: center;
    margin-left: auto;
    margin-right: auto;
  }

  .Header .mobile {
    display: flex;
    flex-direction: column;
    justify-content: center
  }
  .Header .Image {
    width: 100vw;
    align-self: flex-end;
    margin-right: -12vw;
  }
}
