.Shortener {
  background: linear-gradient(180deg, #FFFFFF 50%, #F0F1F6 50%);
}

.Shortener .container {
  margin-top: 30px;
  border-radius: 10px;
  background-color: #3B3054;
  background-image: url("./Vector.svg");
  background-size: cover;
  max-width: 1100px;
  max-height: 168px;
  margin-left: auto;
  margin-right: auto;
  padding-top: 50px;
  padding-bottom: 50px;
  position: relative;
}

.Shortener .greenButton {
  background-color: #2ACFCF;
  color: #FFFFFF;
  border-radius: 10px;
  padding: 20px 40px 20px 40px;
  margin-left: 20px;
  border: none;
  font-weight: 700;
  width: 15%;
}

.Shortener .greenButton:hover {
  background-color: #1ABFBF;
  cursor: pointer;
}

.Shortener .input {
  width: 65%;
  padding-top: 20px;
  padding-bottom: 20px;
  border-radius: 10px;
  font-weight: 300;
  padding-left: 30px;
}

.Shortener .error {
  border-color: #F46262;
  color: #F46262;
}

.Shortener .message {
  padding-left: 8%;
  text-align: left;
  margin-top: 0;
  position: absolute;
  font-size: 14px;
}

.Shortener .loading {
  color: #FFFFFF;
}

@media screen and (max-width: 800px) {
  .Shortener .container {
   background-image: url("./mobile1.svg");
  }
}

@media screen and (max-width: 1000px) {

  .Shortener .greenButton {
   margin-top: 40px;
   width: calc(80% + 40px);
   align-self: flex-start;
   margin-left: 0%;
  }

  .Shortener .input {
    width: 80%;
  }

  .Shortener .message {
    top: 105px;
    left: 9%;
  }
}
